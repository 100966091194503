import { getOrganization } from '@/services/organization/client/get-organization';
import { OrganizationProps } from '@/utils/enums/organization';
import { useQuery } from '@tanstack/react-query';

export const useSearchOrganization = (idOrganization: string) => {
  return useQuery({
    queryKey: ['organization', idOrganization],
    queryFn: async () => {
      const response = await getOrganization(idOrganization);

      return response as OrganizationProps;
    },
    enabled: !!idOrganization,
  });
};
