import Link from 'next/link';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import styled, { css } from 'styled-components';

import {
  enterFromRight,
  enterFromLeft,
  exitToRight,
  exitToLeft,
  scaleIn,
  scaleOut,
} from './animations';

export const DocumentValidationWrapper = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${theme.colors.warning['50']};
    border: 1px solid ${theme.colors.warning['200']};
    color: ${theme.colors.warning['600']};
    border-radius: ${theme.radius.xs};
    padding: ${theme.spacing['06']};
    gap: ${theme.spacing['08']};
    font-weight: ${theme.typography.fontWeight.semiBold};
  `}
`;

export const NavigationMenuRoot = styled(NavigationMenu.Root)`
  position: relative;
`;

export const NavigationMenuList = styled(NavigationMenu.List)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing['08']};
    justify-content: center;
    list-style: none;
    font-weight: ${theme.typography.fontWeight.bold};
    height: 40px;
  `}
`;

export const NavigationMenuTrigger = styled(NavigationMenu.Trigger)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing['08']} ${theme.spacing[12]};
    gap: ${theme.spacing['08']};
    color: ${theme.colors.white};
    font-weight: ${theme.typography.fontWeight.regular};
    text-transform: capitalize;
    height: ${theme.spacing['20']};

    > span {
      max-width: 150px;

      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
    }

    &:hover {
      background-color: ${theme.colors.primary[100]};
      color: ${theme.colors.black};
      cursor: pointer;
    }

    &.projectList {
      background-color: transparent;
      color: ${theme.colors.white};
    }

    &.isMobile {
      border-color: ${theme.colors.gray[900]};
      color: ${theme.colors.gray[900]};

      & > svg {
        color: ${theme.colors.gray[900]};
      }
    }
  `}
`;

export const SvgDown = styled(KeyboardArrowDownIcon)`
  ${({ theme }) => css`
    display: none !important;

    ${theme.breakpoints.tablet960} {
      display: flex !important;
      position: relative;
      color: white;
      top: 1px;
      transition: transform 250ms ease;

      ${NavigationMenuTrigger}:hover & {
        color: black;

        &.projectList {
          color: ${theme.colors.white};
        }

        &.isMobile {
          color: ${theme.colors.gray[900]};
        }
      }

      ${NavigationMenuTrigger}[data-state='open'] & {
        transform: rotate(-180deg);
      }
    }
  `}
`;

export const NavigationMenuContent = styled(NavigationMenu.Content)`
  ${({ theme }) => css`
  top: 20;
  left: 0;
  position: fixed;
  overflow-y: scroll;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${theme.colors.white};
  &[data-state='closed'] {
    animation-name: ${enterFromRight};
  }

  ${theme.breakpoints.mobile540} {
    animation-duration: 250ms;
    animation-timing-function: ease;
    box-shadow: ${theme.shadows.md};
    padding: ${theme.spacing['04']} ${theme.spacing['06']};
    border-radius: ${theme.radius.md};
    background-color: ${theme.colors.white};
    width: 100%;
    position: relative; 

    &[data-motion='from-start'] {
      animation-name: ${enterFromLeft};
    }

    &[data-motion='from-end'] {
      animation-name: ${enterFromRight};
    }

    &[data-motion='to-start'] {
      animation-name: ${exitToLeft};
    }

    &[data-motion='to-end'] {
      animation-name: ${exitToRight};
    }
  }
}
  `}
`;

export const NavigationMenuViewport = styled(NavigationMenu.Viewport)`
  ${({ theme }) => css`
    position: relative;
    transform-origin: top center;
    margin-top: ${theme.spacing[16]};
    transition: width, height, 300ms ease;

    &[data-state='open'] {
      animation: ${scaleIn} 200ms ease;
      z-index: 1;
    }

    &[data-state='closed'] {
      animation: ${scaleOut} 200ms ease;
    }
  `}
`;

export const ViewportPosition = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &.viewPortUnset {
    justify-content: unset;
  }

  &.isMobile {
    justify-content: center;
  }
`;

export const ListUl = styled.ul`
  ${({ theme }) => css`
    width: inherit;
    padding: ${theme.spacing['08']} ${theme.spacing['08']};

    .seeAll {
      display: flex;
      width: 100%;
      align-self: flex-start;
      justify-content: space-between;
      padding: ${({ theme }) => theme.spacing['10']}
        ${({ theme }) => theme.spacing['06']};
    }
    ${theme.breakpoints.mobile540} {
      display: flex;
      flex-direction: column;
      width: max-content;
      .seeAll {
        display: flex;
        width: 100%;
        align-self: flex-start;
        justify-content: space-between;
        padding: ${({ theme }) => theme.spacing['10']}
          ${({ theme }) => theme.spacing['06']};
      }
    }
  `}
`;

export const ListLi = styled.li`
  ${({ theme }) => css`
    padding: ${({ theme }) => theme.spacing['08']}
      ${({ theme }) => theme.spacing['06']};
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing['12']};
    cursor: pointer;

    > svg {
      color: ${theme.colors.gray[900]};
    }

    &.selected {
      background-color: ${theme.colors.accent['25']};
      border-radius: ${theme.radius.xs};
      padding: ${theme.spacing['04']} ${theme.spacing['06']};

      svg {
        color: ${theme.colors.accent[500]};
      }
    }

    & > .title {
      color: ${theme.colors.gray[900]};
      font-size: ${theme.typography.fontSize['18'].size};
      line-height: ${theme.typography.fontSize['18'].lineHeight};
      font-weight: ${theme.typography.fontWeight.semiBold};
      width: 100%;
      padding-top: ${theme.spacing['08']};
    }

    & > .seeAll {
      font-size: ${theme.typography.fontSize['14'].size};
      line-height: ${theme.typography.fontSize['14'].lineHeight};
      font-weight: ${theme.typography.fontWeight.semiBold};
      color: ${theme.colors.accent[500]};
      display: flex;
      gap: ${theme.spacing['08']};
      margin: 0 auto;
      text-decoration: none;

      svg {
        color: ${theme.colors.accent[500]};
      }
    }

    > .addProject {
      border-top: 1px solid ${theme.colors.gray[200]};
      justify-content: flex-start;
      padding: ${theme.spacing['08']} ${theme.spacing['16']};
      width: 100%;

      > button {
        width: 100%;
        align-items: center;
        padding: ${theme.spacing['16']};
      }
    }

    &:last-child {
      padding: 0;

      > button {
        padding: ${theme.spacing['08']} ${theme.spacing['16']};
      }
    }
  `}

  &:hover {
    background-color: #f9fafb;

    border-radius: ${({ theme }) => theme.radius.xs};

    &:first-child {
      border-top-left-radius: ${({ theme }) => theme.radius.md};
      border-top-right-radius: ${({ theme }) => theme.radius.md};
      background-color: unset;
      cursor: unset;
    }

    &:last-child {
      border-bottom-left-radius: ${({ theme }) => theme.radius.md};
      border-bottom-right-radius: ${({ theme }) => theme.radius.md};
    }
  }
`;

export const ListLink = styled(Link)`
  ${({ theme }) => css`
    && {
      display: flex;
      flex-direction: column;
      color: ${theme.colors.gray[800]};
      font-weight: ${theme.typography.fontWeight.semiBold};
      font-size: ${theme.typography.fontSize['16'].size};
      line-height: ${theme.typography.fontSize['16'].lineHeight};
      width: 196px;
      cursor: pointer !important;
      > a {
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
      }
    }
  `}
`;

export const ListDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray[500]};
    font-weight: ${theme.typography.fontWeight.regular};
    font-size: ${theme.typography.fontSize['14'].size};
  `}
`;

export const HeaderTitle = styled.p`
  ${({ theme }) => css`
    align-self: flex-start;
    color: ${theme.colors.gray[700]};
    font-weight: ${theme.typography.fontWeight.semiBold};
    font-size: ${theme.typography.fontSize['14'].size};
  `}
`;
export const UserCard = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing['08']} ${theme.spacing['10']};
    display: flex;
    align-items: center;
    gap: ${theme.spacing['12']};

    ${theme.breakpoints.mobile540} {
      width: 100%;
      padding: ${theme.spacing['08']} ${theme.spacing['10']};
    }
  `}
`;

export const UserInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  `}
`;
export const UserName = styled.div`
  ${({ theme }) => css`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.gray[700]};
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['20'].lineHeight};
    font-weight: ${theme.typography.fontWeight.semiBold};
    max-width: 205px;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
export const UserEmail = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.gray[600]};
    font-size: ${theme.typography.fontSize['12'].size};
    line-height: ${theme.typography.fontSize['18'].lineHeight};
    font-weight: ${theme.typography.fontWeight.regular};
  `}
`;
export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${theme.spacing['12']};
    padding: ${theme.spacing['12']} ${theme.spacing['10']};
  `}
`;
export const OrganizationCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: ${theme.radius.xs};
    gap: ${theme.spacing['08']};
    border: 1px solid ${theme.colors.gray[200]};
    padding: ${theme.spacing['08']} ${theme.spacing['10']};
  `}
`;
export const Organization = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing['08']};
  `}
`;
export const OrganizationIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    color: ${theme.colors.gray[700]};
    width: ${theme.spacing['20']};
    height: ${theme.spacing['20']};
  `}
`;
export const OrganizationIconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.gray[700]};
    width: ${theme.spacing['40']};
    height: ${theme.spacing['40']};
    padding: ${theme.spacing['06']};
    border-radius: ${theme.radius.circle};
    border: 0.75px solid ${theme.colors.gray[200]};
  `}
`;
export const OrganizationInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;
export const OrganizationTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    color: ${theme.colors.gray[500]};
    font-size: ${theme.typography.fontSize['12'].size};
    font-weight: ${theme.typography.fontWeight.regular};
  `}
`;
export const OrganizationName = styled.div`
  ${({ theme }) => css`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: ${theme.colors.gray[700]};
    font-size: ${theme.typography.fontSize['14'].size};
    font-weight: ${theme.typography.fontWeight.semiBold};
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
export const PageButton = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: ${theme.spacing['08']} ${theme.spacing['10']};
    gap: ${theme.spacing['08']};
    cursor: pointer;

    > .last {
      color: ${theme.colors.error[500]};
    }

    &:hover {
      background-color: #f9fafb;

      border-radius: ${({ theme }) => theme.radius.xs};

      &:last-child {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: ${({ theme }) => theme.radius.md};
        border-bottom-right-radius: ${({ theme }) => theme.radius.md};
        background-color: ${({ theme }) => theme.colors.error[100]};
      }
    }
  `}
`;
export const PageIconWrapper = styled.svg`
  ${({ theme }) => css`
    display: flex;
    color: ${theme.colors.gray[700]};
    width: ${theme.spacing['20']};
    height: ${theme.spacing['20']};
    > .last {
      color: ${theme.colors.error[500]};
    }
  `}
`;
export const PageIcon = styled.svg`
  ${({ theme }) => css`
    display: flex;
    color: ${theme.colors.gray[700]};
    > .last {
      color: ${theme.colors.error[500]};
    }
  `}
`;
export const PageTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    color: ${theme.colors.gray[700]};
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['20'].lineHeight};
    font-weight: ${theme.typography.fontWeight.semiBold};

    > .last {
      color: red;
    }
  `}
`;
export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  `}
`;
