import { OrganizationProps } from '@/utils/enums/organization';
import { request } from '@/utils/request';

const url = process.env.NEXT_PUBLIC_BFF;

export const getOrganization = async (organizationId: string) => {
  return request<OrganizationProps>({
    url: url + '/organization/' + organizationId,
    method: 'GET',
  });
};
