import styled, { css } from 'styled-components';

export const Header = styled.header`
  ${({ theme }) => css`
    background: ${theme.colors.headerGradient};
  `};
`;

export const HeaderContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${theme.spacing[16]};
    height: ${theme.spacing[64]};
    max-width: 1024px;
    margin: auto;
  `}
`;

export const Conteiner = styled.div`
  ${({ theme }) => css`
    gap: ${theme.spacing['40']};
    display: flex;
    align-items: center;
  `}
`;

export const HiddenOnMobile = styled.div`
  ${({ theme }) => css`
    display: none;

    ${theme.breakpoints.tablet960} {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: ${theme.spacing['12']};
    }
  `}
`;

export const HiddenOnDesktop = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    ${theme.breakpoints.tablet960} {
      display: none;
    }
  `}
`;

export const Notification = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors.white};

    &:has(.organizationName) {
      & .countNotification {
        margin-right: ${theme.spacing['20']};
      }
    }

    & .organizationName {
      display: flex;
      align-items: center;
      margin-left: ${theme.spacing['08']};
      max-width: 196px;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    padding: ${theme.spacing['08']} ${theme.spacing[12]};
    font-weight: ${theme.typography.fontWeight.bold};
    height: ${theme.spacing[24]};
    color: ${theme.colors.white};
    border-radius: ${theme.radius.xs};
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  `}
`;

export const NoFoundResourcesHeaderContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing['08']};
  `}
`;

export const OrganizationNameContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing['02']};
  `}
`;
export const MenuWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing['08']};
  `}
`;
