'use client';

import { useCallback, useEffect } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useParams, usePathname, useRouter } from 'next/navigation';

import { useAuth } from '@/providers/auth-provider';
import { ListProjectsByUserProps } from '@/services/project/get-list-projects-by-user';
import { isEmpty } from 'lodash';
import nookies from 'nookies';

import {
  Button,
  NavDropdown,
  NavMenu,
  Skeleton,
  getMenuOptions,
} from '@/components';

// import { LanguageDropdownHeader } from './language-dropdown';
import { NavDropdownHeader } from './nav-dropdown';
import * as S from './styles';

type HeaderProps = {
  isHomeOrganization: boolean;
  isHomeProject: boolean;
  isLoadingOrganization?: boolean;
  organizationId?: string;
  organizationName?: string;
  projectList: ListProjectsByUserProps[];
  isError?: boolean;
};

const Header: React.FC<HeaderProps> = ({
  isHomeOrganization = false,
  isHomeProject = false,
  isLoadingOrganization,
  organizationName,
  projectList,
  isError,
}: HeaderProps) => {
  const router = useRouter();
  const { organizationId = '', projectId } = useParams();
  const { user } = useAuth();
  const pathname = usePathname();
  const existUser = user && user?.uid;

  const projectWithName = projectList?.filter((project) => project.projectName);
  const threeProjects =
    projectWithName?.slice(0, 3)?.map((project) => ({
      href: `/auth/organizations/${project.organizationId}/projects/${project.projectId}`,
      label: project.projectName,
    })) || [];

  const hasProject =
    projectList?.length > 0 &&
    projectList.some((project) => project.organizationName !== '');

  const isOwnerFromCurrentProject =
    projectList?.find((project) => project.projectId === projectId)?.role ===
    'OWNER';
  const projectsRender = hasProject && isHomeOrganization;
  const optionsMenu = getMenuOptions({
    organizationId: organizationId as string,
    projectId: projectId as string,
    isProjectOwner: isOwnerFromCurrentProject,
  });

  const redirectViewAllProjects = `/auth/organizations/${organizationId}/projects`;
  const addProject = `/auth/organizations/${organizationId}/projects/create-project`;

  const handleRedirectUserToAddProject = useCallback(() => {
    router.push(addProject);
  }, []);

  useEffect(() => {
    nookies.set(undefined, 'OrganizationId', organizationId as string, {
      path: '/',
    });
    nookies.set(undefined, 'ProjectId', projectId as string, { path: '/' });
  }, [organizationId, projectId]);

  useEffect(() => {
    if (isEmpty(user) && pathname.includes('/auth/')) {
      router.refresh();
    }
  }, []);

  return (
    <S.Header data-testid="header">
      <S.HeaderContent>
        <S.Conteiner>
          <Image
            src="/assets/images/logo-assiny-beta.svg"
            alt="Logo"
            width={117}
            height={24}
            onClick={() => {
              if (projectId) {
                router.push(
                  `/auth/organizations/${organizationId}/projects/${projectId}`
                );
                return;
              }

              return;
            }}
          />
          <S.HiddenOnMobile>
            {hasProject && isHomeProject && (
              <>
                <NavDropdown
                  viewPortPosition="viewPortUnset"
                  label="Gestão"
                  items={optionsMenu.optionsProject}
                />
                <NavDropdown
                  label="Análise"
                  items={optionsMenu.optionsProfile}
                  viewPortPosition="viewPortUnset"
                />

                <S.Button
                  onClick={() =>
                    router.push(
                      `/auth/organizations/${organizationId}/projects/${projectId}/integrations`
                    )
                  }>
                  Integrações
                </S.Button>
              </>
            )}
          </S.HiddenOnMobile>
        </S.Conteiner>

        <S.Notification>
          <S.HiddenOnMobile>
            {/* <LanguageDropdownHeader /> */}
            <NavDropdownHeader
              redirectViewAll={redirectViewAllProjects}
              type="projectList"
              items={threeProjects}
              organizationName={organizationName}
              projects={projectsRender}
            />
          </S.HiddenOnMobile>
          {isHomeOrganization && (
            <S.HiddenOnMobile>
              {isHomeOrganization && isLoadingOrganization && (
                <Skeleton
                  className="organizationName"
                  data-testid="loading"
                  width="40px"
                />
              )}

              {!hasProject && !isLoadingOrganization && (
                <>
                  {!existUser ? (
                    <S.NoFoundResourcesHeaderContainer>
                      <Link href="/login">
                        <Button type="button" color="secondary" size="sm">
                          Entrar
                        </Button>
                      </Link>
                      <Link href="/register">
                        <Button type="button" color="primary" size="sm">
                          Criar conta
                        </Button>
                      </Link>
                    </S.NoFoundResourcesHeaderContainer>
                  ) : (
                    hasProject && (
                      <Button
                        type="button"
                        color="secondary"
                        size="sm"
                        element={Link}
                        href={`/auth/organizations/${organizationId}/projects/create-project`}>
                        Adicionar novo projeto
                      </Button>
                    )
                  )}
                </>
              )}
            </S.HiddenOnMobile>
          )}

          <S.HiddenOnDesktop>
            {!isLoadingOrganization && !existUser ? (
              <S.NoFoundResourcesHeaderContainer>
                <Link href="/login">
                  <Button type="button" color="secondary" size="sm">
                    Entrar
                  </Button>
                </Link>
                <Link href="/register">
                  <Button type="button" color="primary" size="sm">
                    Criar conta
                  </Button>
                </Link>
              </S.NoFoundResourcesHeaderContainer>
            ) : (
              <S.MenuWrapper>
                {projectId && (
                  <NavMenu
                    projectList={projectList}
                    hasOrganization={!isHomeOrganization}
                    organizationName={organizationName}
                    hasProject={projectsRender}
                    redirectViewAllProjects={redirectViewAllProjects}
                    threeProjects={threeProjects}
                    redirectAddProject={addProject}
                    isError={isError}
                    handleRedirectUserToAddProject={
                      handleRedirectUserToAddProject
                    }
                  />
                )}
                {/* <LanguageDropdownHeader /> */}
                <NavDropdownHeader
                  redirectViewAll={redirectViewAllProjects}
                  type="projectList"
                  items={threeProjects}
                  projects={projectsRender}
                  organizationName={organizationName}
                />
              </S.MenuWrapper>
            )}
          </S.HiddenOnDesktop>
        </S.Notification>
      </S.HeaderContent>
    </S.Header>
  );
};

export { Header };
