'use client';
import { useParams } from 'next/navigation';

import { useSearchOrganization } from '@/hooks/organization/use-search-organization';
import { useAuth } from '@/providers/auth-provider';
import {
  AccountBox,
  ReceiptLong,
  AccountBalanceWalletOutlined,
  ArrowForward,
  BugReportOutlined,
  CorporateFareOutlined,
  GroupsOutlined,
  KeyboardArrowRight,
  LogoutOutlined,
  ManageAccountsOutlined,
  Check,
} from '@mui/icons-material';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';

import { AvatarImage } from '@/components/avatar';
import { LinkButton } from '@/components/link-button';

import * as S from './styles';

interface MenuItem {
  href?: string;
  label?: string;
  description?: string;
  icon?: React.ReactNode;
  classNameLi?: string;
  handleClick?: () => void;
}

export interface NavDropdownHeaderProps {
  label?: string;
  items?: MenuItem[];
  type?: 'default' | 'projectList';
  viewPortPosition?: 'viewPortUnset';
  redirectViewAll: string;
  isMobile?: boolean;
  projects?: boolean;
  organizationName?: string;
  handleMenuClick?: () => void;
}

const NavDropdownHeader: React.FC<NavDropdownHeaderProps> = ({
  items,
  type = 'default',
  viewPortPosition,
  isMobile = false,
  redirectViewAll,
  projects,
  organizationName,
}) => {
  const { organizationId = '', projectId = '' } = useParams();
  const { user } = useAuth();

  const triggerClasses = clsx(
    {
      projectList: type === 'projectList',
    },
    viewPortPosition,
    isMobile && 'isMobile'
  );

  const preventDefault = (event: unknown) => {
    const e = event as Event;
    e.preventDefault();
  };

  const hasOrganizationOrProjectId = organizationId || projectId;
  const isAccess = organizationId === '47de10c8-6579-4c35-a9dd-28796f4704b2';

  const { data: organization } = useSearchOrganization(
    organizationId as string
  );

  return (
    <S.NavigationMenuRoot data-testid="nav-dropdown">
      <S.NavigationMenuList>
        <NavigationMenu.Item>
          <S.NavigationMenuTrigger
            onPointerMove={preventDefault}
            onPointerLeave={preventDefault}
            className={triggerClasses}>
            <AvatarImage
              className="avatarImage"
              size="md"
              background="secondary"
              image={user?.photoURL as string}
            />
            <S.SvgDown className={triggerClasses} />
          </S.NavigationMenuTrigger>

          <S.NavigationMenuContent>
            <S.ListUl>
              <S.UserCard>
                <AvatarImage
                  className="avatarImage"
                  size="lg"
                  background="secondary"
                  image={user?.photoURL as string}
                />
                <S.UserInfo>
                  <S.UserName>{user?.displayName}</S.UserName>
                  <S.UserEmail>{user?.email}</S.UserEmail>
                </S.UserInfo>
              </S.UserCard>

              {hasOrganizationOrProjectId && (
                <S.Header>
                  {organizationId && (
                    <S.OrganizationCard>
                      <S.Organization>
                        <S.OrganizationIconWrapper>
                          <S.OrganizationIcon>
                            <CorporateFareOutlined />
                          </S.OrganizationIcon>
                        </S.OrganizationIconWrapper>
                        <S.OrganizationInfo>
                          <S.OrganizationTitle>Organização</S.OrganizationTitle>
                          <S.OrganizationName>
                            {organizationName}
                          </S.OrganizationName>
                        </S.OrganizationInfo>
                      </S.Organization>

                      {organization?.statusValidate !== 'validated' && (
                        <S.DocumentValidationWrapper
                          href={`/auth/organizations/${organizationId}/document-validation`}>
                          <AccountBox />
                          Validar sócios
                        </S.DocumentValidationWrapper>
                      )}
                    </S.OrganizationCard>
                  )}
                  {!!items?.length && (
                    <S.HeaderTitle className="title">
                      Seus projetos
                    </S.HeaderTitle>
                  )}
                </S.Header>
              )}

              {projects && (
                <div>
                  {items?.map((item, index) => (
                    <NavigationMenu.Link asChild key={index}>
                      <S.ListLi
                        key={index}
                        className={clsx(
                          item?.classNameLi,
                          item.href?.includes(projectId as string) && 'selected'
                        )}>
                        <AvatarImage name={item.label} radius="xs" />
                        <S.ListLink
                          href={item?.href || ''}
                          onClick={() => {
                            item?.handleClick && item.handleClick();
                          }}>
                          <a>{item.label}</a>

                          {item.description && (
                            <S.ListDescription>
                              {item.description}
                            </S.ListDescription>
                          )}
                        </S.ListLink>
                        {item.href?.includes(projectId as string) ? (
                          <Check />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </S.ListLi>
                    </NavigationMenu.Link>
                  ))}
                  <NavigationMenu.Link asChild>
                    <S.ListLi className="seeAll">
                      <LinkButton href={redirectViewAll} className="seeAll">
                        Ver projetos <ArrowForward fontSize="medium" />
                      </LinkButton>
                    </S.ListLi>
                  </NavigationMenu.Link>
                </div>
              )}

              <S.Wrapper>
                <NavigationMenu.Link asChild>
                  <S.PageButton href={'/auth/profile'}>
                    <S.PageIconWrapper>
                      <S.PageIcon>
                        <ManageAccountsOutlined />
                      </S.PageIcon>
                    </S.PageIconWrapper>
                    <S.PageTitle>Perfil</S.PageTitle>
                  </S.PageButton>
                </NavigationMenu.Link>
                <NavigationMenu.Link asChild>
                  <S.PageButton href={'/auth/profile/financial/bank-accounts'}>
                    <S.PageIconWrapper>
                      <S.PageIcon>
                        <AccountBalanceWalletOutlined />
                      </S.PageIcon>
                    </S.PageIconWrapper>
                    <S.PageTitle>Contas bancárias</S.PageTitle>
                  </S.PageButton>
                </NavigationMenu.Link>
                {projectId && (
                  <NavigationMenu.Link asChild>
                    <S.PageButton
                      href={`/auth/organizations/${organizationId}/projects/${projectId}/members`}>
                      <S.PageIconWrapper>
                        <S.PageIcon>
                          <GroupsOutlined />
                        </S.PageIcon>
                      </S.PageIconWrapper>
                      <S.PageTitle>Usuários do Projeto</S.PageTitle>
                    </S.PageButton>
                  </NavigationMenu.Link>
                )}
                {projectId && isAccess && (
                  <NavigationMenu.Link asChild>
                    <S.PageButton
                      href={`/auth/organizations/${organizationId}/projects/${projectId}/invoices`}>
                      <S.PageIconWrapper>
                        <S.PageIcon>
                          <ReceiptLong />
                        </S.PageIcon>
                      </S.PageIconWrapper>
                      <S.PageTitle>Divisão notas fiscais</S.PageTitle>
                    </S.PageButton>
                  </NavigationMenu.Link>
                )}
                <NavigationMenu.Link asChild>
                  <S.PageButton href={'/auth/organizations'}>
                    <S.PageIconWrapper>
                      <S.PageIcon>
                        <CorporateFareOutlined />
                      </S.PageIcon>
                    </S.PageIconWrapper>

                    <S.PageTitle>Organizações</S.PageTitle>
                  </S.PageButton>
                </NavigationMenu.Link>
                <NavigationMenu.Link asChild>
                  <S.PageButton
                    href={
                      'https://forms.clickup.com/31032041/f/xk0q9-64653/AJVLP7U9AJ189EKYVB'
                    }>
                    <S.PageIconWrapper>
                      <S.PageIcon>
                        <BugReportOutlined />
                      </S.PageIcon>
                    </S.PageIconWrapper>
                    <S.PageTitle>Relatar um problema</S.PageTitle>
                  </S.PageButton>
                </NavigationMenu.Link>

                <S.PageButton href={'/signout'} className="last">
                  <S.PageIconWrapper>
                    <S.PageIcon className="last">
                      <LogoutOutlined />
                    </S.PageIcon>
                  </S.PageIconWrapper>
                  <S.PageTitle className="last">Sair</S.PageTitle>
                </S.PageButton>
              </S.Wrapper>
              <S.ListLi />
            </S.ListUl>
          </S.NavigationMenuContent>
        </NavigationMenu.Item>
      </S.NavigationMenuList>

      <S.ViewportPosition className={triggerClasses}>
        <S.NavigationMenuViewport />
      </S.ViewportPosition>
    </S.NavigationMenuRoot>
  );
};

export { NavDropdownHeader };
